import React, { Component } from "react";
import { FormattedMessage } from "react-intl";

class Footer extends Component {
    render() {
        return (
            <React.Fragment>
                <footer className="footer-area" id="contact">
                    <div className="footer-wrapper">
                        <div className="row align-items-end row--0">
                            <div className="col-lg-6">
                                <div className="footer-left">
                                    <div className="inner">
                                        <span><FormattedMessage id="footer.question"/></span>
                                        <h2><FormattedMessage id="footer.callToAction"/></h2>
                                        <a className="rn-button-style--2" href="https://wa.me/5511963097009?text=Quero%20conversar%20sobre%20um%20projeto">
                                            <span><FormattedMessage id="footer.contact"/></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="footer-right" data-black-overlay="6">
                                    <div className="row">
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-6 col-sm-6 col-12">
                                            <div className="footer-link">
                                                <h4>Links</h4>
                                                <ul className="ft-link">
                                                    <li><a href="#portfolio"><FormattedMessage id="menu.portfolio"/></a></li>
                                                    <li><a href="#about"><FormattedMessage id="menu.about"/></a></li>
                                                    <li><a href="https://wa.me/5511963097009?text=Quero%20conversar%20sobre%20um%20projeto"><FormattedMessage id="services.callToAction"/></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                                            <div className="footer-link">
                                                <h4><FormattedMessage id="footer.contact"/></h4>
                                                <ul className="ft-link">
                                                    <li><a href="https://wa.me/5511963097009?text=Quero%20conversar%20sobre%20um%20projeto"><FormattedMessage id="footer.sendMessage"/></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}

                                        <div className="col-lg-12">
                                            <div className="copyright-text">
                                                <p>Copyright © 2017 Dark n' Black iStudio. <FormattedMessage id="footer.rights"/></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        )
    }
}
export default Footer;