import React, { Component } from "react";
import Slider from "react-slick";
import { portfolioSlick2 } from "../../../page-demo/script";
import { FormattedMessage } from 'react-intl';

const PortfolioList = [
    {
        image: 'image-1',
        category: 'portfolio.category.develop',
        title: 'portfolio.acoconsp',
        url: 'https://acoconsp.com.br'
    },
    {
        image: 'image-2',
        category: 'portfolio.category.develop',
        title: 'portfolio.caldervol',
        url: 'https://caldervol.com'
    },
    {
        image: 'image-3',
        category: 'portfolio.category.develop',
        title: 'portfolio.aprendendodesenhar',
        url: 'https://aprendendodesenhar.com'
    },
    {
        image: 'image-4',
        category: 'portfolio.category.develop',
        title: 'portfolio.williandsantos',
        url: 'https://williandsantos.com'
    },
    {
        image: 'image-5',
        category: 'portfolio.category.develop',
        title: 'portfolio.vivadamente',
        url: 'https://vivadamente.com'
    },
    {
        image: 'image-6',
        category: 'portfolio.category.develop',
        title: 'portfolio.ultimatereviews',
        url: 'https://theultimatereviews.com'
    },
    {
        image: 'image-7',
        category: 'portfolio.category.develop',
        title: 'portfolio.tavernadabagunca',
        url: 'https://tavernadabagunca.com'
    }
]

class Portfolio extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="portfolio-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="section-title">
                                    <h2><FormattedMessage id="portfolio.title"/></h2>
                                    <p><FormattedMessage id="portfolio.description"/></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="portfolio-slick-activation mt--70 mt_sm--40">
                        <Slider {...portfolioSlick2}>
                            {PortfolioList.map((value , index) => (
                                <div className="portfolio" key={index}>
                                    <div className="thumbnail-inner">
                                        <div className={`thumbnail ${value.image}`}></div>
                                        <div className={`bg-blr-image ${value.image}`}></div>
                                    </div>
                                    <div className="content">
                                        <div className="inner">
                                            <p><FormattedMessage id={value.category}/></p>
                                            <h4 className="title"><a target="blank" href={value.url}><FormattedMessage id={value.title}/></a></h4>
                                            <div className="portfolio-button">
                                                <a target="blank" className="rn-btn" href={value.url}><FormattedMessage id="portfolio.link"/></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Portfolio;