// React Required
import React, { Component } from "react";
import ReactDOM from "react-dom";

// Create Import File
import "./index.scss";

import PageScrollTop from "./component/PageScrollTop";

import DarkMainDemo from "./dark/MainDemo";
import error404 from "./elements/error404";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import IntlProviderConfigured from "./_i18n/IntlProviderConfigured";

import { LANGUAGES } from "./_i18n/languages";

const MultiLanguageRoute = (props) => {
  const defaultLanguage = LANGUAGES.pt.urlLang;
  const hasLang = props.computedMatch.params.lang;
  const is404Page = props.path;
  const isBasePathWithoutLang = props.path === "/";

  if (isBasePathWithoutLang) return <Redirect to={`/${defaultLanguage}`} />;
  if (!hasLang && !is404Page) return <Redirect to={`/${defaultLanguage}`} />;

  return <Route {...props} />;
};

class Root extends Component {
  render() {
    return (
      <IntlProviderConfigured>
        <BrowserRouter basename={"/"}>
          <PageScrollTop>
            <Switch>
              <MultiLanguageRoute exact path="/" />
              <MultiLanguageRoute
                exact
                path={`${process.env.PUBLIC_URL}/:lang`}
                component={DarkMainDemo}
              />
              <MultiLanguageRoute
                path={`${process.env.PUBLIC_URL}/:lang/404`}
                component={error404}
              />
              <MultiLanguageRoute path="*" component={error404} />
            </Switch>
          </PageScrollTop>
        </BrowserRouter>
      </IntlProviderConfigured>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.register();
